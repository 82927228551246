import Head from "next/head";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function Home() {
  const [companydata, setCompanyData] = useState({});

const fetchCompany = async () => {
  await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/company-detail?populate=*`
  )
    .then((res) => res.json())
    .then((data) => {
      setCompanyData(data.data.attributes);
    });
  
};

  useEffect(() => {
  fetchCompany()
  }, []);
  


  return (
    <div className="">
      <Head>
        <title>{companydata.companyName} Dashboard</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="#" />
      </Head>

      <main className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center space-y-3">
        <h2 className="font-bold text-3xl">Welocome To {companydata.companyName}</h2>
        <Link href="/login">
          <button className="border w-[150px] ">
            Login
          </button>
        </Link>
        </div>

      </main>
    </div>
  );
}

// // This gets called on every request
// export async function getServerSideProps() {
//   // Fetch data from external API
//   const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/home-page?populate=*`);
//   const data = await res.json();

//   // Pass data to the page via props
//   return { props: { data } };
// }
